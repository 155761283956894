.ant-input,
.ant-input-number-input {
  border-radius: @input-border-radius;
  border: 2px solid @layout-low-contrast;
}

.ant-input-number {
  border: none;
  width: 100%;
}

.ant-input-number-input {
  height: @input-total-height;
  &:hover {
    border-color: #5aa7fa;
  }
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
  border-radius: @input-border-radius;
  border: 2px solid @layout-low-contrast;
  padding: 10px 8px;

  .ant-input-prefix {
    margin-right: 14px;

    svg {
      width: 24px;
    }
  }

  .ant-input {
    border-radius: 0;
  }
}

.ant-form-item-label {
  > label {
    text-transform: capitalize;
    .caption-1-mixin();
  }
}

//disabled style
.ant-input[disabled],
.ant-input-affix-wrapper-disabled {
  background-color: @white;
}

// remove inner square on errored inputs
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
  box-shadow: none;
}

//remove the x icon in errored inputs
.ant-input-feedback-icon,
.ant-input-number-feedback-icon {
  display: none;
}

.ant-input-number-affix-wrapper-has-feedback {
  border: none;
  padding: 0;
  width: 100%;
  .ant-input-number-input {
    border-color: @error-mid-contrast;
    height: 46px;
    padding-left: 5px !important;
  }
}
